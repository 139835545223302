import React, { useEffect, useState, createContext, useContext } from "react"
import { useCoords } from "../../store/CoordsContext"
import { Element } from "react-scroll"
import Container from "../../components/Container/Container"
import Box from "components/Box"
import Typography from "components/Typography"
import SvgCircleAnimation from "../../components/Circles/SvgCircleAnimation"
import { SectionElement, Intro } from "../../components/Container/styles"
import { H4, H3, H1 } from "../../components/Typo/Headlines"
import { ExcerptParagraphs, Paragraphs } from "components/Typo/Text"
import SvgRibbon from "../../components/Ribbon/SvgRibbon"
import FixedModal from "../../components/Modal/FixedModal"
import GalleryModal from "../../components/Modal/GalleryModal"
import SectionGrid from "../../components/Container/SectionGrid"
import Audio from "../../components/Player/Audio"
import {
  SectionImage,
  SectionFigcaption,
  SectionFigure,
} from "../../components/Container/styles"
import { GalleryModalContext } from "../../store/GalleryModalContext"
import { isMobile, isDesktop } from "utils"
import { PlayerContext } from "../../store/PlayerContext"
import MobilePlayer from "../../components/Player/MobilePlayer"
import SEO from "../../components/seo"
import { getGPUTier } from "detect-gpu"
import { AnimatePresence, motion } from "framer-motion"

export const ModalContext = createContext({
  modal: null,
  setModal: arg => arg,
})

const Ziemia = ({ pageContext }) => {
  // TODO: only query & seo in this component, pass data to generic component

  const { sections } = pageContext?.post?.fields
  const [modal, setModal] = useState(null)
  const [galleryModal, setGalleryModal] = useState(null)
  const [coords, setCoords] = useCoords()
  const [height, setHeight] = useState("calc(100vh - 135px)")
  const playerContext = useContext(PlayerContext)
  const [showComponent, setShowComponent] = useState(false)

  useEffect(() => {
    if (pageContext.post.fields.audio) {
      const track = pageContext.post.fields.audio
      const url = track.url ? track.url : track.file
      playerContext.setPlayerContext({
        type: "SET_PLAYER",
        payload: {
          title: track.title,
          url: url,
          playing: false,
          description: track.description,
        },
      })
    }
  }, [])

  ////console.log("X", pageContext)

  useEffect(() => {
    if (isMobile) {
      setHeight("100vh")
    } else if (isDesktop) {
      setHeight("calc(100vh - 135px)")
    } else {
      setHeight("calc(100vh - 150px)")
    }

    let intro
    ;(async () => {
      const detectLowSpec = async () => {
        const gpuTier = await getGPUTier()

        if (gpuTier.tier > 1) {
          if (!showComponent) {
            intro = setTimeout(() => {
              setShowComponent(true)
            }, 5000)
          }
        } else {
          // setLowSpecPC(true)
          intro = setTimeout(() => {
            setShowComponent(true)
          }, 100)
        }
      }
      await detectLowSpec()
    })()

    return () => {
      intro && clearTimeout(intro)
    }
  }, [])

  useEffect(() => {
    if (coords) {
      setShowComponent(true)
    }
  }, [coords])

  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      <GalleryModalContext.Provider value={{ galleryModal, setGalleryModal }}>
        <SEO
          lang={"en"}
          title={"Into the future | The Lem Galaxy"}
          description={
            "E-book readers, smartphones, the Internet - these are just some of the inventions predicted by Lem."
          }
          image={"/lem.png"}
        ></SEO>
        <AnimatePresence>
          {(showComponent || isMobile) && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Container en>
                <div style={{ position: "relative" }}>
                  <SvgCircleAnimation
                    pageContext={pageContext}
                  ></SvgCircleAnimation>
                </div>
                <Element
                  id={"scene"}
                  containerId={"scene"}
                  style={{
                    overflowY: "auto",
                    height: height,
                    opacity: modal ? 0.4 : 1,
                  }}
                >
                  <SectionElement>
                    <Intro>
                      <H1
                        content={"url(/titles/1_Lems_glimpses.svg)"}
                        width={"120%"}
                        top={"-25%"}
                        left={"-10%"}
                      >
                        Lem's glimpses
                        <br />
                        of the future
                      </H1>
                      <ExcerptParagraphs
                        dangerouslySetInnerHTML={{
                          __html: pageContext.post.post_content,
                        }}
                      ></ExcerptParagraphs>
                      {pageContext?.post?.fields?.audio?.url && (
                        <Audio track={pageContext.post.fields.audio} />
                      )}
                    </Intro>
                    {sections.map((x, i) => {
                      return (
                        <Element
                          className={`test${i}`}
                          name={`element_${i}`}
                          key={i}
                        >
                          <section style={{ marginBottom: "50px" }}>
                            <SectionGrid layout={x.layout}>
                              {x.title && (
                                <Box
                                  display="flex"
                                  alignItems="baseline"
                                  marginLeft={isMobile ? -32 : -40}
                                >
                                  <img
                                    src="/chapter_icon.svg"
                                    width="29"
                                    height="auto"
                                  />
                                  {i < 9 ? (
                                    <Typography
                                      variant="chapterIndex"
                                      color="text.secondary"
                                      marginRight="7px"
                                      marginLeft={isMobile ? "5px" : "9px"}
                                    >
                                      0{i + 1}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant="chapterIndex"
                                      color="text.secondary"
                                      marginRight="7px"
                                      marginLeft={isMobile ? "5px" : "9px"}
                                    >
                                      {i + 1}
                                    </Typography>
                                  )}
                                  <Box display="flex" flexDirection="column">
                                    <H3>{x.title}</H3>
                                    {x.subtitle && <H4>{x.subtitle}</H4>}
                                  </Box>
                                </Box>
                              )}
                              {isMobile ? (
                                <>
                                  <SectionFigure>
                                    {x.Image && (
                                      <>
                                        <SectionImage
                                          onClick={() => setGalleryModal(x)}
                                          src={x.Image.url}
                                        ></SectionImage>
                                        <SectionFigcaption>
                                          {x.Image.caption}
                                        </SectionFigcaption>
                                      </>
                                    )}
                                  </SectionFigure>
                                  <div>
                                    <Paragraphs
                                      dangerouslySetInnerHTML={{
                                        __html: x.content,
                                      }}
                                    ></Paragraphs>
                                    {x.popup && x.popup.title && (
                                      <SvgRibbon
                                        popup={x.popup}
                                        title={x.popup.title}
                                      ></SvgRibbon>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <Paragraphs
                                      dangerouslySetInnerHTML={{
                                        __html: x.content,
                                      }}
                                    ></Paragraphs>
                                    {x.popup && x.popup.title && (
                                      <SvgRibbon
                                        popup={x.popup}
                                        title={x.popup.title}
                                      ></SvgRibbon>
                                    )}
                                  </div>
                                  <SectionFigure>
                                    {x.Image && (
                                      <>
                                        <SectionImage
                                          onClick={() => setGalleryModal(x)}
                                          src={x.Image.url}
                                        ></SectionImage>
                                        <SectionFigcaption>
                                          {x.Image.caption}
                                        </SectionFigcaption>
                                      </>
                                    )}
                                  </SectionFigure>
                                </>
                              )}
                            </SectionGrid>
                          </section>
                        </Element>
                      )
                    })}
                  </SectionElement>
                  <div style={{ marginBottom: "100px" }}></div>
                </Element>
                <MobilePlayer></MobilePlayer>
              </Container>
            </motion.div>
          )}
        </AnimatePresence>
        <FixedModal modal={modal} closeModal={() => setModal(null)} />
        <GalleryModal
          modal={galleryModal}
          closeModal={() => setGalleryModal(null)}
        />
      </GalleryModalContext.Provider>
    </ModalContext.Provider>
  )
}

export default Ziemia
